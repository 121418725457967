import React from "react";
import ReactDOM from "react-dom";
import "./css/index.css";
import App from "./App";

import { createStore } from "redux";
import { allReducers } from "./reducers/";
import { Provider } from "react-redux";

import { Auth0Provider } from "@auth0/auth0-react";

const store = createStore(allReducers);

ReactDOM.render(
  <Auth0Provider
    domain="dev-n64nru1t.us.auth0.com"
    clientId="q1KpGTT6tO7Buhms9C3hSPi0Bg4jYcuC"
    redirectUri={window.location.origin}
    audience="AniTeki-api"
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <Provider store={store}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);
