import "../css/Header.css";
import AnitekiLogo from "../static/Logo.png";
import axios from "axios";

function redirectAnilist(token) {
  axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/ani/authenticate`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      window.location.href = `${response.data.url}`;
    })
    .catch((error) => {
      console.log("An error occured while redirecting ANI " + error);
      return false;
    });
}

function redirectMAL(token) {
  axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/mal/authenticate`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      window.location.href = `${response.data.url}`;
      return true;
    })
    .catch((error) => {
      console.log("An error occured while redirecting MAL " + error);
      return false;
    });
}

function Header(props) {
  if (props.usernameANI !== "" && props.usernameMAL !== "") {
    return (
      <div className="HeaderContainer">
        <a className="AniTekiLogo">
          <img src={AnitekiLogo} width={150} alt="AniTeki" />
        </a>
        <div className="AvatarUsernameContainer">
          <div className="Avatar">
            <img
              src={props.avatar}
              height={"100px"}
              alt={`Avatar of ${props.usernameANI}`}
            />
          </div>
          <div className="UsernameANI">{props.usernameANI}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="HeaderContainer">
        <div>
          <div className="genericText">Not logged in!</div>
          <div className="genericText">
            {props.usernameANI == "" || props.usernameANI == undefined ? (
              <div>
                <button onClick={() => redirectAnilist(props.auth0Token)}>
                  Login with Anilist
                </button>
              </div>
            ) : (
              <a>Logged into Anilist as {props.usernameANI}</a>
            )}
          </div>
          <div className="genericText">
            {props.usernameMAL == "" || props.usernameMAL == undefined ? (
              <div>
                <button onClick={() => redirectMAL(props.auth0Token)}>
                  Login with MAL
                </button>
              </div>
            ) : (
              <a>Logged into MAL as {props.usernameMAL}</a>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
