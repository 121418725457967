import "./css/App.css";

import Main from "./Container/Main";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./Components/LoginButton";

function App() {
  const { isAuthenticated, isLoading } = useAuth0();
  return (
    <div className="App">
      {isLoading && <label>Loading...</label>}
      {isAuthenticated && !isLoading && <Main />}
      {!isAuthenticated && !isLoading && <LoginButton />}
    </div>
  );
}

export default connect(null)(App);
