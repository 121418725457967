import "../css/Buttons.css";
import { connect } from "react-redux";
import { setSearchResults } from "../actions";
import cookies from "js-cookie";
const axios = require("axios").default;

async function updateAnimeStatus(aniId, malId, newStatus, props) {
  if (aniId != "Unavailable") {
    // Since we used the anilist api enum for the status values we can just pass them through.
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/ani/updateAnimeStatus/${aniId}/${newStatus}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${props.auth0Token}`,
            aniAuthToken: cookies.get("aniAuthToken"),
          },
        }
      )
      .then((response) => {
        if (response.error) {
          console.error("An error occurred while setting a new score!");
          return;
        }
      })
      .catch((error) => {
        console.error(`An error occured while setting a new score: ${error}`);
      });
  }

  if (malId != "Unavailable") {
    // The status values need to be converted from the anilist api enum to the mal api
    let convertedStatus = "";
    switch (newStatus) {
      case "CURRENT":
        convertedStatus = "watching";
        break;
      case "PLANNING":
        convertedStatus = "plan_to_watch";
        break;
      case "COMPLETED":
        convertedStatus = "completed";
        break;
      case "DROPPED":
        convertedStatus = "dropped";
        break;
      case "PAUSED":
        convertedStatus = "on_hold";
        break;
      default:
        convertedStatus = "watching";
    }

    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mal/updateAnimeStatus/${malId}/${convertedStatus}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${props.auth0Token}`,
            malAuthToken: cookies.get("malAuthToken"),
          },
        }
      )
      .then((response) => {
        if (response.error) {
          console.error("An error occurred while setting a new score!");
          return;
        }
      })
      .catch((error) => {
        console.error(`An error occured while setting a new score: ${error}`);
      });
  }
}

function addEntryToList(element, props) {
  updateAnimeStatus(element.aniId, element.malId, "CURRENT", props);
}

function SearchResults(props) {
  if (props.searchResults[0] != "empty") {
    return (
      <div>
        {props.searchResults.map((element, index) => {
          return (
            <div className="searchEntryContainer" key={"searchResult" + index}>
              <img className="CoverImage" src={element.image} />
              <div className="searchResultTitle">{element.titleAni}</div>
              <div className="searchResultEpisodes">{element.episodes}</div>
              <div className="searchResultYear">{element.year}</div>
              <button
                className="btn"
                onClick={() => addEntryToList(element, props)}
              >
                Add
              </button>
            </div>
          );
        })}
      </div>
    );
  } else {
    return <div>No results!</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth0Token: state.auth0Token,
    usernameANI: state.usernameANI,
    usernameMAL: state.usernameMAL,
    userId: state.userId,
    loginStatusANI: state.loginStatusANI,
    loginStatusMAL: state.loginStatusMAL,
    animelistANI: state.currentAnimelistANI,
    animelistMAL: state.currentAnimelistMAL,
    searchResults: state.searchResults,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchResults: (results) => dispatch(setSearchResults(results)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
