import "../css/Buttons.css";

import cookies from "js-cookie";
import { connect } from "react-redux";
import {
  setAvatarANI,
  setUsernameANI,
  setUsernameMAL,
  setLoginANI,
  setLoginMAL,
  setUserIdANI,
  setAuth0Token,
  setSearchResults,
} from "../actions";
const axios = require("axios").default;

let input = "";

async function searchMedia(auth0Token, props) {
  await axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/general/search/${input}`, {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${auth0Token}`,
        malAuthToken: cookies.get("malAuthToken"),
        aniAuthToken: cookies.get("aniAuthToken"),
      },
    })
    .then((response) => {
      if (
        response.error ||
        response.data == null ||
        response.data == undefined ||
        response.data == undefined
      ) {
        console.error("An error occured while searching!");
        return;
      }
      // We got something back!
      console.log(JSON.stringify(response));
      if (JSON.parse(response.data.matchedEntries).length > 0) {
        props.setSearchResults(JSON.parse(response.data.matchedEntries));
      } else {
        props.setSearchResults(["empty"]);
      }
    })
    .catch((error) => {
      console.error("An error occured while searching: " + error);
    });
}

function handleInput(text) {
  input = text.target.value;
}

function SearchBar(props) {
  return (
    <div>
      <input
        className="inputText"
        onChange={(changed) => handleInput(changed)}
      ></input>
      <button
        className="btn"
        onClick={() => searchMedia(props.auth0Token, props)}
      >
        Submit
      </button>
      <button className="btn" onClick={() => props.setSearchResults([])}>
        Reset
      </button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    counter: state.counter,
    usernameANI: state.usernameANI,
    usernameMAL: state.usernameMAL,
    loginStatusANI: state.loginStatusANI,
    loginStatusMAL: state.loginStatusMAL,
    login: state.login,
    avatar: state.avatar,
    userId: state.userId,
    auth0Token: state.auth0Token,
    searchResults: state.searchResults,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsernameANIValue: (usernameANI) => dispatch(setUsernameANI(usernameANI)),
    setUsernameMALValue: (usernameMAL) => dispatch(setUsernameMAL(usernameMAL)),
    setAvatarValue: (avatar) => dispatch(setAvatarANI(avatar)),
    setLoginStatusANI: (status) => dispatch(setLoginANI(status)),
    setLoginStatusMAL: (status) => dispatch(setLoginMAL(status)),
    setUserIdValue: (userId) => dispatch(setUserIdANI(userId)),
    setAuth0TokenValue: (token) => dispatch(setAuth0Token(token)),
    setSearchResults: (results) => dispatch(setSearchResults(results)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
