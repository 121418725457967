import { useEffect, useState } from "react";
import "../css/AnimeList.css";
import "../css/Input.css";
import { connect } from "react-redux";
import { setCurrentAnimelistANI, setCurrentAnimelistMAL } from "../actions";
import cookies from "js-cookie";
import LogoutButton from "./LogoutButton";

const axios = require("axios").default;

function AnimeList(props) {
  var entriesANI = [];

  var entriesMAL = [];

  const [entriesMerged, setEntriesMerged] = useState([]);

  useEffect(() => {
    refreshWatchlist();
  }, [props.loginStatusANI, props.loginStatusMAL]);

  useEffect(() => {
    PopulateMergedArray();
  }, [props.animelistANI, props.animelistMAL]);

  async function refreshWatchlist() {
    // First, we get the Anilist watchlist
    if (props.usernameANI) {
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/ani/getCurrentWatchlist/${props.userId}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${props.auth0Token}`,
              aniAuthToken: cookies.get("aniAuthToken"),
            },
          }
        )
        .then((response) => {
          if (
            response.error ||
            response.data == null ||
            response.data == undefined
          ) {
            console.error("An error occured while getting the ANI list!");
            localStorage.setItem("currentWatchlistANI", "");
            props.setCurrentAnimelistANIValue("");
            return;
          }
          localStorage.setItem(
            "currentWatchlistANI",
            JSON.stringify(response.data)
          );
          props.setCurrentAnimelistANIValue(response.data);
        })
        .catch((error) => {
          console.error(
            "An error occured while requesting the current Anilist watchlist: " +
              error
          );
        });
    }

    // Second, we get the Myanimelist watchlist
    if (props.usernameMAL) {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/mal/getCurrentWatchlist`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${props.auth0Token}`,
            malAuthToken: cookies.get("malAuthToken"),
          },
        })
        .then((response) => {
          if (
            response.error ||
            response.data == null ||
            response.data == undefined ||
            response.data == ""
          ) {
            console.error("An error occured while getting the MAL list!");
            localStorage.setItem("currentWatchlistMAL", "");
            props.setCurrentAnimelistMALValue("");
            return;
          }
          localStorage.setItem(
            "currentWatchlistMAL",
            JSON.stringify(response.data.malList)
          );
          props.setCurrentAnimelistMALValue(response.data.malList);
        })
        .catch((error) => {
          console.error(
            "An error occured while requesting the current MAL watchlist: " +
              error
          );
        });
    }
  }

  async function updateProgressANI(id, newEpisode) {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/ani/updateEpisodeCount/${id}/${newEpisode}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${props.auth0Token}`,
            aniAuthToken: cookies.get("aniAuthToken"),
          },
        }
      )
      .then((response) => {
        if (response.error) {
          console.error("An error occured while updating the ANI list!");
          return;
        }
        refreshWatchlist();
      })
      .catch((error) => {
        console.error("An error occured while updating an ANI entry: " + error);
      });
  }

  async function updateProgressMAL(id, newEpisode) {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mal/updateEpisodeCount/${id}/${newEpisode}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${props.auth0Token}`,
            malAuthToken: cookies.get("malAuthToken"),
          },
        }
      )
      .then((response) => {
        if (response.error) {
          console.error("An error occured while getting the MAL list!");
          return;
        }
        refreshWatchlist();
      })
      .catch((error) => {
        console.error("An error occured while updating an MAL entry: " + error);
      });
  }

  function updateProgressCombined(malId, aniId, newEpisode) {
    updateProgressMAL(malId, newEpisode);
    updateProgressANI(aniId, newEpisode);
  }

  async function updateMalScore(id, newScore) {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/mal/updateAnimeScore/${id}/${newScore}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${props.auth0Token}`,
            malAuthToken: cookies.get("malAuthToken"),
          },
        }
      )
      .then((response) => {
        if (response.error) {
          console.error("An error occurred while setting a new score!");
          return;
        }
        refreshWatchlist();
      })
      .catch((error) => {
        console.error(`An error occured while setting a new score: ${error}`);
      });
  }

  async function handleChangedMalScore(id, changedEvent) {
    updateMalScore(id, changedEvent.target.value);
  }

  async function updateAniScore(id, newScore) {
    await axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/ani/updateAnimeScore/${id}/${newScore}`,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${props.auth0Token}`,
            aniAuthToken: cookies.get("aniAuthToken"),
          },
        }
      )
      .then((response) => {
        if (response.error) {
          console.error("An error occurred while setting a new score!");
          return;
        }
        refreshWatchlist();
      })
      .catch((error) => {
        console.error(`An error occured while setting a new score: ${error}`);
      });
  }

  async function handleChangedAniScore(id, changedEvent) {
    updateAniScore(id, changedEvent.target.value);
  }

  async function updateAnimeStatus(aniId, malId, newStatus) {
    if (aniId != "Unavailable") {
      // Since we used the anilist api enum for the status values we can just pass them through.
      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/ani/updateAnimeStatus/${aniId}/${newStatus}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${props.auth0Token}`,
              aniAuthToken: cookies.get("aniAuthToken"),
            },
          }
        )
        .then((response) => {
          if (response.error) {
            console.error("An error occurred while setting a new score!");
            return;
          }
        })
        .catch((error) => {
          console.error(`An error occured while setting a new score: ${error}`);
        });
    }

    if (malId != "Unavailable") {
      // The status values need to be converted from the anilist api enum to the mal api
      let convertedStatus = "";
      switch (newStatus) {
        case "CURRENT":
          convertedStatus = "watching";
          break;
        case "PLANNING":
          convertedStatus = "plan_to_watch";
          break;
        case "COMPLETED":
          convertedStatus = "completed";
          break;
        case "DROPPED":
          convertedStatus = "dropped";
          break;
        case "PAUSED":
          convertedStatus = "on_hold";
          break;
        default:
          convertedStatus = "watching";
      }

      await axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/mal/updateAnimeStatus/${malId}/${convertedStatus}`,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${props.auth0Token}`,
              malAuthToken: cookies.get("malAuthToken"),
            },
          }
        )
        .then((response) => {
          if (response.error) {
            console.error("An error occurred while setting a new score!");
            return;
          }
        })
        .catch((error) => {
          console.error(`An error occured while setting a new score: ${error}`);
        });
    }
    refreshWatchlist();
  }

  async function handleAnimeStatusChanged(aniId, malId, changedEvent) {
    updateAnimeStatus(aniId, malId, changedEvent.target.value);
  }

  function AddEntriesToAniArray() {
    if (!props.animelistANI?.list) {
      return;
    }

    entriesANI = new Array();
    props.animelistANI.list.map((element, index) => {
      var entry = {
        id: element.media.id,
        titleNative: element.media.title.native,
        titleRomaji: element.media.title.romaji,
        episodes:
          element.media.episodes == null ? "unknown" : element.media.episodes,
        progress: element.progress,
        score: element.score,
        idMal: element.media.idMal,
        coverImage: element.media.coverImage.large,
      };
      entriesANI.push(entry);
    });
  }

  function AddEntriesToMalArray() {
    if (!props.animelistMAL?.data) {
      return;
    }

    entriesMAL = new Array();
    props.animelistMAL.data.map((element, index) => {
      var entry = {
        id: element.node.id,
        titleRomaji: element.node.title,
        titleNative: element.node.alternative_titles.ja,
        episodes: element.node.num_episodes,
        progress: element.list_status.num_episodes_watched,
        score: element.list_status.score,
        coverImage: element.node.main_picture.large,
      };
      entriesMAL.push(entry);
    });
  }

  function PopulateMergedArray() {
    setEntriesMerged([]);
    AddEntriesToAniArray();
    AddEntriesToMalArray();

    if (entriesMAL.length !== entriesANI.length) {
      if (entriesMAL.length > entriesANI.length) {
        entriesMAL.map((element, index) => {
          var entry = entriesANI.find((value) => value.idMal == element.id);
          if (entry === undefined) {
            var mergedEntry = {
              titleRomaji: element.titleRomaji,
              titleNative: element.titleNative,
              malId: element.id,
              aniId: "Unavailable",
              malProgress: element.progress,
              aniProgress: "Unavailable",
              malScore: element.score,
              aniScore: "",
              totalEpisodes: element.episodes,
              coverImage: element.coverImage,
            };
            setEntriesMerged((old) => [...old, mergedEntry]);
          }
        });
      }
    }

    if (entriesANI.length > 0 || entriesMAL.length > 0) {
      entriesANI.map((element) => {
        var malEntry = entriesMAL.find((value) => value.id == element.idMal);
        if (malEntry === undefined) {
          var mergedEntry = {
            titleRomaji: element.titleRomaji,
            titleNative: element.titleNative,
            malId: "Unavailable",
            aniId: element.id,
            malProgress: "Unavailable",
            aniProgress: element.progress,
            malScore: "",
            aniScore: element.score,
            totalEpisodes: element.episodes,
            coverImage: element.coverImage,
          };
        } else {
          let combined = 0;
          if (element.progress == malEntry.progress) {
            combined = malEntry.progress;
          } else {
            combined = "Unavailable";
          }

          var mergedEntry = {
            titleRomaji: element.titleRomaji,
            titleNative: element.titleNative,
            malId: malEntry.id,
            aniId: element.id,
            malProgress: malEntry.progress,
            aniProgress: element.progress,
            malScore: malEntry.score,
            aniScore: element.score,
            combinedProgress: combined,
            totalEpisodes: element.episodes,
            coverImage: element.coverImage,
          };
        }
        setEntriesMerged((old) => [...old, mergedEntry]);
      });
    }
  }

  return (
    <div className="AnimeListOuterContainer">
      <div id="AnimeListContainer">
        {entriesMerged.map((element, index) => {
          return (
            <div key={index} className="AnimeListElement">
              <div className="ElementCoverContainer">
                <img className="CoverImage" src={element.coverImage} />
              </div>
              <div className="ElementInfoContainer">
                <div className="ElementTitleContainer">
                  <div className="ElementTitleNative">
                    {element.titleNative}
                  </div>
                  <div className="ElementTitleRomaji">
                    ({element.titleRomaji})
                  </div>
                </div>

                <div className="ElementProgressContainer">
                  <a className="progressText">
                    Combined: {element.combinedProgress}/
                    {element.totalEpisodes == null
                      ? "unknown"
                      : element.totalEpisodes}
                  </a>
                  <div>
                    {element.combinedProgress != "Unavailable" ? (
                      <div>
                        <button
                          className="btn"
                          onClick={() =>
                            updateProgressCombined(
                              element.malId,
                              element.aniId,
                              --element.combinedProgress
                            )
                          }
                        >
                          -1
                        </button>
                        <button
                          className="btn"
                          onClick={() =>
                            updateProgressCombined(
                              element.malId,
                              element.aniId,
                              ++element.combinedProgress
                            )
                          }
                        >
                          +1
                        </button>
                      </div>
                    ) : (
                      <a></a>
                    )}
                  </div>

                  <a
                    className="progressText"
                    href={`https://myanimelist.net/anime/${element.malId}`}
                  >
                    MAL: {element.malProgress}/
                    {element.totalEpisodes == null
                      ? "unknown"
                      : element.totalEpisodes}
                  </a>
                  <div>
                    {element.malProgress != "Unavailable" ? (
                      <div>
                        <button
                          className="btn"
                          onClick={() =>
                            updateProgressMAL(
                              element.malId,
                              --element.malProgress
                            )
                          }
                        >
                          -1
                        </button>
                        <button
                          className="btn"
                          onClick={() =>
                            updateProgressMAL(
                              element.malId,
                              ++element.malProgress
                            )
                          }
                        >
                          +1
                        </button>
                      </div>
                    ) : (
                      <a></a>
                    )}
                  </div>

                  <a
                    className="progressText"
                    href={`https://anilist.co/anime/${element.aniId}`}
                  >
                    ANILIST: {element.aniProgress}/
                    {element.totalEpisodes == null
                      ? "unknown"
                      : element.totalEpisodes}
                  </a>
                  <div>
                    {element.aniProgress != "Unavailable" ? (
                      <div>
                        <button
                          className="btn"
                          onClick={() =>
                            updateProgressANI(
                              element.aniId,
                              --element.aniProgress
                            )
                          }
                        >
                          -1
                        </button>
                        <button
                          className="btn"
                          onClick={() =>
                            updateProgressANI(
                              element.aniId,
                              ++element.aniProgress
                            )
                          }
                        >
                          +1
                        </button>
                      </div>
                    ) : (
                      <a></a>
                    )}
                  </div>
                </div>

                <div className="scoreContainer">
                  <a className="scoreText">MAL score: </a>
                  <input
                    className="input"
                    id={element.malId}
                    value={element.malScore}
                    onChange={(changed) =>
                      handleChangedMalScore(element.malId, changed)
                    }
                  ></input>
                </div>

                <div className="scoreContainer">
                  <a className="scoreText">ANI score: </a>
                  <input
                    className="input"
                    id={element.aniId}
                    value={element.aniScore}
                    onChange={(changed) =>
                      handleChangedAniScore(element.aniId, changed)
                    }
                  ></input>
                </div>

                <div className="watchingStatusContainer">
                  <select
                    className="btn"
                    type=""
                    onChange={(changed) =>
                      handleAnimeStatusChanged(
                        element.aniId,
                        element.malId,
                        changed
                      )
                    }
                  >
                    <option value="CURRENT">Watching</option>
                    <option value="COMPLETED">Completed</option>
                    <option value="DROPPED">Dropped</option>
                    <option value="PAUSED">On hold</option>
                    <option value="PLANNING">Planned</option>
                  </select>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth0Token: state.auth0Token,
    usernameANI: state.usernameANI,
    usernameMAL: state.usernameMAL,
    userId: state.userId,
    loginStatusANI: state.loginStatusANI,
    loginStatusMAL: state.loginStatusMAL,
    animelistANI: state.currentAnimelistANI,
    animelistMAL: state.currentAnimelistMAL,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentAnimelistANIValue: (list) =>
      dispatch(setCurrentAnimelistANI(list)),
    setCurrentAnimelistMALValue: (list) =>
      dispatch(setCurrentAnimelistMAL(list)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnimeList);
