import { connect } from "react-redux";
import { setSearchResults } from "../actions";
import SearchBar from "../Components/SearchBar";
import SearchResults from "../Components/SearchResults";

function SearchSection(props) {
  return (
    <div>
      <SearchBar searchResults={props.searchResults} />
      <SearchResults searchResults={props.searchResults} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    searchResults: state.searchResults,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchResults: (results) => dispatch(setSearchResults(results)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchSection);
