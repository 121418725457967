import React, { useEffect } from "react";
import cookies from "js-cookie";
import { connect } from "react-redux";
import {
  setAvatarANI,
  setUsernameANI,
  setUsernameMAL,
  setLoginANI,
  setLoginMAL,
  setUserIdANI,
  setAuth0Token,
  setSearchResults,
} from "../actions";

import Header from "../Components/Header";
import AnimeList from "../Components/AnimeList";
import OptionsBar from "../Components/OptionsBar";
import SearchSection from "./SearchSection";
import { useAuth0 } from "@auth0/auth0-react";

import "../css/Main.css";

const axios = require("axios").default;

function clearCacheMAL() {
  localStorage.setItem("currentWatchlistMAL", null);
  localStorage.setItem("usernameMAL", "");
}

function clearCacheANI() {
  localStorage.setItem("currentWatchlistANI", null);
  localStorage.setItem("usernameANI", "");
}

function callMALUserInformation(auth0Token) {
  return axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/mal/getuserinformation`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${auth0Token}`,
        malAuthToken: cookies.get("malAuthToken"),
      },
    }
  );
}

function callMALRefreshAccessToken(auth0Token) {
  return axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/mal/refreshaccesstoken`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${auth0Token}`,
        malRefreshToken: cookies.get("malRefreshToken"),
      },
    }
  );
}

function callANIUserInformation(auth0Token) {
  return axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/ani/getuserinformation`,
    {
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${auth0Token}`,
        aniAuthToken: cookies.get("aniAuthToken"),
      },
    }
  );
}

async function getMalUserInformation(props) {
  await callMALUserInformation(props.auth0Token)
    .then(async (response) => {
      // If we get back an error it could either be that the auth token provided was invalid
      // or that it just expired and thus we need to refresh it.
      if (response.data.error) {
        clearCacheMAL();
        props.setLoginStatusMAL(false);
        localStorage.setItem("loginStatusMAL", false);
        // As mentioned above we try to refresh the access token
        getMalUserInformationWithRefreshToken(props);
      } else if (response.data["MAL user information"].name) {
        props.setUsernameMALValue(response.data["MAL user information"].name);
        localStorage.setItem(
          "usernameMAL",
          response.data["MAL user information"].name
        );
        props.setLoginStatusMAL(true);
        localStorage.setItem("loginStatusMAL", true);
      }
    })
    .catch((error) => {
      console.log("MAL: Not logged in! " + error);
      props.setLoginStatusMAL(false);
      localStorage.setItem("loginStatusMAL", false);
      clearCacheMAL();
    });
}

async function getMalUserInformationWithRefreshToken(props) {
  await callMALRefreshAccessToken(props.auth0Token)
    .then(async (response) => {
      if (response.data.error) {
        clearCacheMAL();
        props.setLoginStatusMAL(false);
        localStorage.setItem("loginStatusMAL", false);
      } else if (
        response.data.newRefreshToken &&
        response.data.newAccessToken
      ) {
        cookies.set("malRefreshToken", response.data.newRefreshToken, {
          expires: 31,
        });
        cookies.set("malAuthToken", response.data.newAccessToken, {
          expires: 31,
        });

        // Now that the access token is refresh we can try one more time to call the MAL api
        await callMALUserInformation(props.auth0Token).then((response) => {
          if (response.data.error) {
            // Nope, no luck!
            console.log(
              "Seems like the refresh of the access token was not successful..."
            );
          } else if (response.data["MAL user information"].name) {
            props.setUsernameMALValue(
              response.data["MAL user information"].name
            );
            localStorage.setItem(
              "usernameMAL",
              response.data["MAL user information"].name
            );
            props.setLoginStatusMAL(true);
            localStorage.setItem("loginStatusMAL", true);
          }
        });
      }
    })
    .catch((error) => {
      console.log("MAL: Not logged in! " + error);
      props.setLoginStatusMAL(false);
      localStorage.setItem("loginStatusMAL", false);
      clearCacheMAL();
    });
}

async function getAniUserInformation(props) {
  await callANIUserInformation(props.auth0Token)
    .then((response) => {
      if (response.data.error) {
        props.setLoginStatusANI(false);
        localStorage.setItem("loginStatusANI", false);
        clearCacheANI();
      } else {
        if (response.data.username) {
          props.setUsernameANIValue(response.data.username);
          localStorage.setItem("usernameANI", response.data.username);
        }

        if (response.data.avatar) {
          props.setAvatarValue(response.data.avatar);
          localStorage.setItem("avatar", response.data.avatar);
        }

        if (response.data.id) {
          props.setUserIdValue(response.data.id);
          localStorage.setItem("userId", response.data.id);
        }
        props.setLoginStatusANI(true);
        localStorage.setItem("loginStatusANI", true);
      }
    })
    .catch(() => {
      console.log("Anilist: Not logged in!");
      props.setLoginStatusANI(false);
      localStorage.setItem("loginStatusANI", false);
      clearCacheANI();
    });
}

function Main(props) {
  const { getAccessTokenSilently } = useAuth0();

  useEffect(async () => {
    try {
      getAccessTokenSilently({
        audience: "AniTeki-api",
      }).then((result) => {
        props.setAuth0TokenValue(result);
        localStorage.setItem("auth0Token", result);
      });
    } catch (error) {
      console.error(
        `An error occured while getting the Access Token: ${error}`
      );
    }

    if (!props.auth0Token) {
      return;
    }

    if (cookies.get("malAuthToken")) {
      getMalUserInformation(props);
    } else if (cookies.get("malRefreshToken")) {
      getMalUserInformationWithRefreshToken(props);
    }

    if (cookies.get("aniAuthToken")) {
      getAniUserInformation(props);
    }
  }, []);

  return (
    <div id="MainOuterContainer">
      <Header
        usernameANI={props.usernameANI}
        usernameMAL={props.usernameMAL}
        avatar={props.avatar}
        auth0Token={props.auth0Token}
        class="HeaderOuterContainer"
      />
      <OptionsBar />
      <SearchSection />
      <AnimeList class="AnimeListOuterContainer" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    counter: state.counter,
    usernameANI: state.usernameANI,
    usernameMAL: state.usernameMAL,
    loginStatusANI: state.loginStatusANI,
    loginStatusMAL: state.loginStatusMAL,
    login: state.login,
    avatar: state.avatar,
    userId: state.userId,
    auth0Token: state.auth0Token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsernameANIValue: (usernameANI) => dispatch(setUsernameANI(usernameANI)),
    setUsernameMALValue: (usernameMAL) => dispatch(setUsernameMAL(usernameMAL)),
    setAvatarValue: (avatar) => dispatch(setAvatarANI(avatar)),
    setLoginStatusANI: (status) => dispatch(setLoginANI(status)),
    setLoginStatusMAL: (status) => dispatch(setLoginMAL(status)),
    setUserIdValue: (userId) => dispatch(setUserIdANI(userId)),
    setAuth0TokenValue: (token) => dispatch(setAuth0Token(token)),
    setSearchResults: (results) => dispatch(setSearchResults(results)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
