import { bindActionCreators, combineReducers } from "redux";
import defaultAvatar from "./omot.png";

const usernameMALReducer = (state, action) => {
  switch (action.type) {
    case "SET_USERNAME_MAL":
      state = action.payload;
      return state;
    default:
      if (localStorage.usernameMAL != null) {
        state = localStorage.usernameMAL;
      } else {
        state = "";
      }
      return state;
  }
};

const usernameANIReducer = (state, action) => {
  switch (action.type) {
    case "SET_USERNAME_ANI":
      state = action.payload;
      return state;
    default:
      if (localStorage.usernameANI != null) {
        state = localStorage.usernameANI;
      } else {
        state = "";
      }
      return state;
  }
};

const avatarReducer = (state = defaultAvatar, action) => {
  switch (action.type) {
    case "SET_AVATAR_MAL":
      return (state = action.payload);
    case "SET_AVATAR_ANI":
      return (state = action.payload);
    default:
      if (localStorage.avatar != null) {
        return (state = localStorage.avatar);
      } else {
        return state;
      }
  }
};

const loginStatusMALReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_LOGIN_STATUS_MAL":
      return (state = action.payload);
    default:
      if (localStorage.loginStatusMAL) {
        return (state = localStorage.loginStatusMAL);
      } else {
        return state;
      }
  }
};

const loginStatusANIReducer = (state = false, action) => {
  switch (action.type) {
    case "SET_LOGIN_STATUS_ANI":
      return (state = action.payload);
    default:
      if (localStorage.loginStatusANI) {
        return (state = localStorage.loginStatusANI);
      } else {
        return state;
      }
  }
};

const userIdReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_USER_ID_MAL":
      return (state = action.payload);
    case "SET_USER_ID_ANI":
      return (state = action.payload);
    default:
      if (localStorage.userId != null) {
        return (state = localStorage.userId);
      } else {
        return state;
      }
  }
};

const currentAnimelistANIReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_CURRENT_ANIMELIST_ANI":
      return (state = action.payload);
    default:
      if (
        localStorage.getItem("currentWatchlistANI") !== null &&
        localStorage.getItem("currentWatchlistANI") !== "undefined" &&
        localStorage.getItem("currentWatchlistANI") !== ""
      ) {
        return (state = JSON.parse(
          localStorage.getItem("currentWatchlistANI")
        ));
      } else {
        return (state = {});
      }
  }
};

const currentAnimelistMALReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_CURRENT_ANIMELIST_MAL":
      return (state = action.payload);
    default:
      if (
        localStorage.getItem("currentWatchlistMAL") != null &&
        localStorage.getItem("currentWatchlistMAL") !== "undefined" &&
        localStorage.getItem("currentWatchlistMAL") !== ""
      ) {
        return (state = JSON.parse(
          localStorage.getItem("currentWatchlistMAL")
        ));
      } else {
        return (state = {});
      }
  }
};

const auth0TokenReducer = (state = "", action) => {
  switch (action.type) {
    case "SET_AUTH0_TOKEN":
      return (state = action.payload);
    default:
      if (localStorage.auth0Token != null) {
        return (state = localStorage.getItem("auth0Token"));
      } else {
        return (state = "");
      }
  }
};

const searchResultsReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_SEARCH_RESULTS":
      return (state = action.payload);
    default:
      return state;
  }
};

export const allReducers = combineReducers({
  usernameMAL: usernameMALReducer,
  usernameANI: usernameANIReducer,
  avatar: avatarReducer,
  loginStatusANI: loginStatusANIReducer,
  loginStatusMAL: loginStatusMALReducer,
  userId: userIdReducer,
  currentAnimelistANI: currentAnimelistANIReducer,
  currentAnimelistMAL: currentAnimelistMALReducer,
  auth0Token: auth0TokenReducer,
  searchResults: searchResultsReducer,
});

export default allReducers;
