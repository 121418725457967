export const setUsernameMAL = (username) => {
  return {
    type: "SET_USERNAME_MAL",
    payload: username,
  };
};

export const setUsernameANI = (username) => {
  return {
    type: "SET_USERNAME_ANI",
    payload: username,
  };
};

export const setAvatarMAL = (avatar) => {
  return {
    type: "SET_AVATAR_MAL",
    payload: avatar,
  };
};

export const setAvatarANI = (avatar) => {
  return {
    type: "SET_AVATAR_ANI",
    payload: avatar,
  };
};

export const setLoginMAL = (status) => {
  return {
    type: "SET_LOGIN_STATUS_MAL",
    payload: status,
  };
};

export const setLoginANI = (status) => {
  return {
    type: "SET_LOGIN_STATUS_ANI",
    payload: status,
  };
};

export const setUserIdMAL = (userId) => {
  return {
    type: "SET_USER_ID_MAL",
    payload: userId,
  };
};

export const setUserIdANI = (userId) => {
  return {
    type: "SET_USER_ID_ANI",
    payload: userId,
  };
};

export const setCurrentAnimelistMAL = (list) => {
  return {
    type: "SET_CURRENT_ANIMELIST_MAL",
    payload: list,
  };
};

export const setCurrentAnimelistANI = (list) => {
  return {
    type: "SET_CURRENT_ANIMELIST_ANI",
    payload: list,
  };
};

export const setAuth0Token = (token) => {
  return {
    type: "SET_AUTH0_TOKEN",
    payload: token,
  };
};

export const setSearchResults = (results) => {
  return {
    type: "SET_SEARCH_RESULTS",
    payload: results,
  };
};
