import { useEffect, useState } from "react";
import "../css/AnimeList.css";
import { connect } from "react-redux";
import {
  setAvatarANI,
  setUsernameANI,
  setUsernameMAL,
  setLoginANI,
  setLoginMAL,
  setUserIdANI,
  setAuth0Token,
} from "../actions";
import cookies from "js-cookie";
import LogoutButton from "./LogoutButton";

const axios = require("axios").default;

function logoutFromAnilist(props) {
  console.log(JSON.stringify(props));
  localStorage.setItem("currentWatchlistANI", null);
  localStorage.setItem("usernameANI", "");
  localStorage.setItem("loginStatusANI", "");
  localStorage.setItem("userId", "");
  localStorage.setItem("avatar", "");
  cookies.set("aniAuthToken", "");

  props.setLoginStatusANI(false);
}

function logoutFromMAL(props) {
  localStorage.setItem("currentWatchlistMAL", null);
  localStorage.setItem("usernameMAL", "");
  localStorage.setItem("loginStatusMAL", "");
  cookies.set("malAuthToken", "");
  cookies.set("malRefreshToken", "");

  props.setLoginStatusMAL(false);
}

function OptionsBar(props) {
  return (
    <div>
      <button className="btn" onClick={() => logoutFromAnilist(props)}>
        Log out from Anilist
      </button>
      <button className="btn" onClick={() => logoutFromMAL(props)}>
        Log out from MAL
      </button>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    counter: state.counter,
    usernameANI: state.usernameANI,
    usernameMAL: state.usernameMAL,
    loginStatusANI: state.loginStatusANI,
    loginStatusMAL: state.loginStatusMAL,
    login: state.login,
    avatar: state.avatar,
    userId: state.userId,
    auth0Token: state.auth0Token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUsernameANIValue: (usernameANI) => dispatch(setUsernameANI(usernameANI)),
    setUsernameMALValue: (usernameMAL) => dispatch(setUsernameMAL(usernameMAL)),
    setAvatarValue: (avatar) => dispatch(setAvatarANI(avatar)),
    setLoginStatusANI: (status) => dispatch(setLoginANI(status)),
    setLoginStatusMAL: (status) => dispatch(setLoginMAL(status)),
    setUserIdValue: (userId) => dispatch(setUserIdANI(userId)),
    setAuth0TokenValue: (token) => dispatch(setAuth0Token(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsBar);
